<template>
  <div class="container">
    <div class="logo"></div>
    <div class="form-container">
      <div>Votre mot de passe a été réinitialisé.</div>
      <p>
        Vous pouvez maintenant vous connecter avec votre nouveau mot de passe.
      </p>
      <div class="mt-193">
        <q-btn
          label="Se connecter"
          class="btn-blue"
          type="button"
          @click="$router.push('login')"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'StepThreeChangedPassword'
}
</script>

<template>
  <div class="row">
    <div class="col-md-5 col-lg-4 left">
      <div class="column">
        <div class="col">
          <div class="logo"></div>
        </div>
        <div class="col-10 left-content">
          <span>{{ step }}/{{ steps }}</span>
          <div>Créer un document</div>

          <p>
            Pour créer un document, rien de plus simple. Il vous suffit de
            cliquer sur le bouton “Créer un document” et de sélectionner le
            Modèle que vous souhaitez utiliser.
          </p>

          <p>
            Désormais, vous pouvez vérifier votre document et le modifier à
            votre guise avant de le sauvegarder et de le télécharger.
          </p>
        </div>
        <div class="col triple-button">
          <q-btn label="Back" class="btn-white" @click="back" />
          <q-btn label="Skip" class="btn-white" @click="skip" />
          <q-btn label="Next" class="btn-blue" @click="setStepGuide" />
        </div>
      </div>
    </div>
    <div class="col-md-7 col-lg-8 right">
      <div class="row">
        <div class="col-10 offset-1">
          <img src="@/assets/images/registration-steps/step-five.svg" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    step: {
      type: Number,
      default: 1
    },
    steps: {
      type: Number,
      default: 6
    }
  },
  methods: {
    skip() {
      this.$emit('skip')
    },
    setStepGuide() {
      this.$emit('setStepGuide', this.step + 1)
    },
    back() {
      this.$emit('setStepGuide', this.step - 1)
    }
  }
}
</script>

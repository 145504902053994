<template>
  <q-form @submit="onSubmit" class="q-gutter-md">
    <q-input
      square
      outlined
      bottom-slots
      v-model="password"
      placeholder="Mot de passe"
      type="password"
      lazy-rules
      :rules="[val => (val && val.length > 0) || 'Veuillez entrer votre mot de passe']"
    >
      <template v-slot:append>
        <q-avatar>
          <img src="../../assets/icons/eye.svg" />
        </q-avatar>
      </template>
      <template v-slot:hint>
        Sélectionnez un mot de passe
      </template>
    </q-input>

    <q-input
      square
      outlined
      bottom-slots
      v-model="confirmPassword"
      placeholder="Confirmez le mot de passe"
      type="password"
      lazy-rules
      :rules="[val => checkConfirmPassword(val) || 'Veuillez confirmer votre mot de passe']"
    >
      <template v-slot:append>
        <q-avatar>
          <img src="../../assets/icons/eye.svg" />
        </q-avatar>
      </template>
      <template v-slot:hint>
        Confirmez le mot de passe
      </template>
    </q-input>

    <div class="bottom-buttons">
      <q-btn label="Créer le compte" type="submit" class="btn-registration" />
    </div>
  </q-form>
</template>

<script>
export default {
  data() {
    return {
      password: null,
      confirmPassword: null
    }
  },
  methods: {
    async onSubmit() {
      try {
        const { email, password } = this.$route.params
        const user = await this.$Amplify.Auth.signIn(email, password)
        await this.$Amplify.Auth.completeNewPassword(user, this.password)
        this.$emit('registered')
      } catch (err) {
        this.showNotify(err.message)
      }
    },
    showNotify(message) {
      const { color, icon, timeout, position } = this.$q.config.alert
      this.$q.notify({ color, icon, message, position, timeout })
    },
    checkConfirmPassword(val) {
      if (val !== this.password) return 'Passwords do not match'
      if (val && val.length > 0) return true
    }
  }
}
</script>

<template>
  <q-form @submit="onSubmit" class="q-gutter-md">
    <q-input
      square
      outlined
      bottom-slots
      v-model="email"
      placeholder="Entrez votre adresse mail"
      type="email"
      lazy-rules
      :rules="[val => checkEmail(val) || 'Veuillez entrer votre adresse email']"
    >
      <template v-slot:hint>
        Adresse mail
      </template>
    </q-input>

    <q-input
      square
      outlined
      bottom-slots
      v-model="password"
      placeholder="Entrez votre mot de passe"
      :type="type"
      lazy-rules
      :rules="[
        val => checkPassword(val) || 'Veuillez entrer votre mot de passe'
      ]"
    >
      <template v-slot:append>
        <q-avatar>
          <img
            src="../../assets/icons/eye.svg"
            @click="type = type === 'password' ? 'text' : 'password'"
          />
        </q-avatar>
      </template>
      <template v-slot:hint>
        Mot de passe
      </template>
    </q-input>

    <router-link to="/forgot-password">
      Mot de passe oublié ?
    </router-link>

    <div>
      <q-btn
        :loading="loading"
        label="Se connecter"
        type="submit"
        class="btn-login"
      />
    </div>
  </q-form>
</template>

<script>
export default {
  name: 'FormLogin',
  data() {
    // console.log('identityPoolId:' + process.env.VUE_APP_IDENTITY_POOL_ID)
    // console.log('region:' + process.env.VUE_APP_REGION)
    // console.log('identityPoolRegion:' + process.env.VUE_APP_REGION)
    // console.log('userPoolId:' + process.env.VUE_APP_USER_POOL_ID)
    // console.log(
    //   'userPoolWebClientId:' + process.env.VUE_APP_USER_POOL_WEB_CLIENT_ID
    // )
    return {
      email: null,
      password: null,
      type: 'password',
      loading: false
    }
  },
  methods: {
    async onSubmit() {
      this.loading = true
      try {
        const user = await this.$Amplify.Auth.signIn(this.email, this.password)

        if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
          this.$router.push({
            name: 'Registration',
            params: {
              name: user.challengeParam.userAttributes.name,
              email: this.email,
              password: this.password,
              isCanOpen: 'yes'
            }
          })
        } else {
          this.$router.push('/')
        }
      } catch (err) {
        this.showNotify('Username or password is incorrect')
        this.loading = false
      }
    },
    showNotify(message) {
      const { color, icon, timeout, position } = this.$q.config.alert
      this.$q.notify({ color, icon, message, position, timeout })
    },
    checkEmail(val) {
      if (val && val.length && !this.validateEmail(val))
        return 'Incorrect email'
      if (val && val.length > 0) return true
    },
    validateEmail(email) {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(String(email).toLowerCase())
    },
    checkPassword(val) {
      if (val && val.length < 6) return 'Minimum length 6 characters'
      if (val && val.length >= 6) return true
    }
  }
}
</script>

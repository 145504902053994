<template>
  <div class="row">
    <div class="col-md-5 col-lg-4 left">
      <div class="column">
        <div class="col">
          <div class="logo"></div>
        </div>
        <div class="col-10 left-content">
          <span>{{ step }}/{{ steps }}</span>
          <div>Créer un nouveau projet</div>

          <p>
            Pour créer un nouveau projet, il vous suffit de cliquer sur le
            button “Nouveau projet +” dans la Page d’Accueil ou dans la Page des
            Projets.
          </p>
        </div>
        <div class="col triple-button">
          <q-btn label="Retour" class="btn-white" @click="back" />
          <q-btn label="Passer" class="btn-white" @click="skip" />
          <q-btn label="Suivant" class="btn-blue" @click="setStepGuide" />
        </div>
      </div>
    </div>
    <div class="col-md-7 col-lg-8 right">
      <div class="row">
        <div class="col-10 offset-1">
          <img src="@/assets/images/registration-steps/step-three.svg" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    step: {
      type: Number,
      default: 1
    },
    steps: {
      type: Number,
      default: 6
    }
  },
  methods: {
    skip() {
      this.$emit('skip')
    },
    setStepGuide() {
      this.$emit('setStepGuide', this.step + 1)
    },
    back() {
      this.$emit('setStepGuide', this.step - 1)
    }
  }
}
</script>

<template>
  <div class="container">
    <div class="logo"></div>
    <div class="form-container">
      <div>Créez votre nouveau mot de passe</div>
      <FormChangePassword @setStep="setStep" :email.sync="email" />
    </div>
  </div>
</template>

<script>
import FormChangePassword from '@/components/login/FormChangePassword.vue'

export default {
  name: 'StepTwoChangePassword',
  props: {
    email: {
      type: String,
      default: ''
    }
  },
  components: {
    FormChangePassword
  },
  methods: {
    setStep(step) {
      this.$emit('setStep', step)
    }
  }
}
</script>

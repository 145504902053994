<template>
  <div class="row">
    <div class="col-md-5 col-lg-4 left">
      <div class="column">
        <div class="col">
          <div class="logo"></div>
        </div>
        <div class="col-10 left-content">
          <span>{{ step }}/{{ steps }}</span>
          <div>Réglages</div>

          <p>
            Bylaw vous permet de gérer l’ensemble de votre cabinet et des droits
            qui sont accordés aux utilisateurs en quelques clics.
          </p>

          <p>
            Rendez-vous dans la page de Réglages pour créer un nouvel
            utilisateurs, modifier les droits de chacun d’entre eux ou encore
            modifier le département auquel il appartient.
          </p>

          <p>
            Bien entendu, vous pouvez toujours nous contacter afin d’en
            apprendre plus ou si vous souhaitez nous poser des questions.
          </p>

          <p class="p-bold" style="font-size: 18px; margin-top: 40px;">
            Vous pouvez désormais utiliser pleinement Bylaw !
          </p>
        </div>
        <div class="col">
          <q-btn label="Retour" class="btn-white" @click="back" />
          <q-btn label="Accéder à Bylaw" class="btn-blue" @click="skip" />
        </div>
      </div>
    </div>
    <div class="col-md-7 col-lg-8 right">
      <div class="row">
        <div class="col-10 offset-1">
          <img src="@/assets/images/registration-steps/step-six.svg" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    step: {
      type: Number,
      default: 1
    },
    steps: {
      type: Number,
      default: 6
    }
  },
  methods: {
    skip() {
      this.$emit('skip')
    },
    back() {
      this.$emit('setStepGuide', this.step - 1)
    }
  }
}
</script>

<template>
  <div class="container container-steps">
    <StepOne
      v-if="stepGuide === 1"
      :step="stepGuide"
      :steps="allSteps"
      @skip="skip"
      @setStepGuide="setStepGuide"
    />
    <StepTwo
      v-if="stepGuide === 2"
      :step="stepGuide"
      :steps="allSteps"
      @skip="skip"
      @setStepGuide="setStepGuide"
    />
    <StepThree
      v-if="stepGuide === 3"
      :step="stepGuide"
      :steps="allSteps"
      @skip="skip"
      @setStepGuide="setStepGuide"
    />
    <StepFour
      v-if="stepGuide === 4"
      :step="stepGuide"
      :steps="allSteps"
      @skip="skip"
      @setStepGuide="setStepGuide"
    />
    <StepFive
      v-if="stepGuide === 5"
      :step="stepGuide"
      :steps="allSteps"
      @skip="skip"
      @setStepGuide="setStepGuide"
    />
    <StepSix
      v-if="stepGuide === 6"
      :step="stepGuide"
      :steps="allSteps"
      @skip="skip"
      @setStepGuide="setStepGuide"
    />
  </div>
</template>

<script>
import StepOne from '@/components/registration/steps/StepOne.vue'
import StepTwo from '@/components/registration/steps/StepTwo.vue'
import StepThree from '@/components/registration/steps/StepThree.vue'
import StepFour from '@/components/registration/steps/StepFour.vue'
import StepFive from '@/components/registration/steps/StepFive.vue'
import StepSix from '@/components/registration/steps/StepSix.vue'

export default {
  components: {
    StepSix,
    StepFive,
    StepFour,
    StepThree,
    StepTwo,
    StepOne
  },
  props: {
    stepGuide: {
      type: Number,
      default: 1
    },
    allSteps: {
      type: Number,
      default: 6
    }
  },
  methods: {
    setStepGuide(step) {
      this.$emit('setStepGuide', step)
    },
    skip() {
      sessionStorage.removeItem('isRegistered')
      sessionStorage.removeItem('isGuide')
      sessionStorage.removeItem('stepGuide')
      this.$router.push('/')
    }
  }
}
</script>

<template>
  <div class="login">
    <div class="container">
      <div class="logo"></div>
      <div class="form-container">
        <div class="mb-40">Connection à votre compte</div>
        <FormLogin />
      </div>
    </div>
  </div>
</template>

<script>
import FormLogin from '@/components/login/FormLogin.vue'

export default {
  name: 'MainLogin',
  components: {
    FormLogin
  }
}
</script>

<template>
  <div :class="{ registration: true, 'first-step': !isRegistered && !isGuide }">
    <FirstContainer v-if="!isRegistered" @registered="handleRegistered" />

    <SecondContainer
      v-if="isRegistered && !isGuide"
      @startGuide="handleStartGuide"
    />

    <ThirdContainer
      v-if="isGuide"
      :stepGuide="stepGuide"
      :allSteps="allSteps"
      @setStepGuide="handleSetStepGuide"
    />
  </div>
</template>

<script>
import FirstContainer from '@/components/registration/containers/FirstContainer.vue'
import SecondContainer from '@/components/registration/containers/SecondContainer.vue'
import ThirdContainer from '@/components/registration/containers/ThirdContainer.vue'

export default {
  name: 'MainRegistration',
  components: {
    ThirdContainer,
    SecondContainer,
    FirstContainer
  },
  data() {
    return {
      isRegistered: false,
      isGuide: false,
      stepGuide: 1,
      allSteps: 6
    }
  },
  created() {
    const keysBooleans = ['isRegistered', 'isGuide']
    const keysNumbers = ['stepGuide']

    keysBooleans.forEach(item => {
      if (sessionStorage.getItem(item)) {
        this[item] = !!sessionStorage.getItem(item)
      }
    })

    keysNumbers.forEach(item => {
      if (sessionStorage.getItem(item)) {
        this[item] = +sessionStorage.getItem(item)
      }
    })
  },
  methods: {
    handleRegistered() {
      this.isRegistered = true
      sessionStorage.setItem('isRegistered', 'true')
    },
    handleStartGuide() {
      this.isGuide = true
      sessionStorage.setItem('isGuide', 'true')
    },
    handleSetStepGuide(step) {
      this.stepGuide = step
      sessionStorage.setItem('stepGuide', '' + step)
    }
  }
}
</script>

<template>
  <div
    :class="{
      login: true,
      'forgot-password': currentStep === 1,
      'change-password': currentStep === 2,
      'changed-password': currentStep === 3
    }"
  >
    <StepOneForgotPassword
      v-if="currentStep === 1"
      @setStep="setStep"
      @setEmail="setEmail"
    />
    <StepTwoChangePassword
      v-if="currentStep === 2"
      @setStep="setStep"
      :email.sync="email"
    />
    <StepThreeChangedPassword v-if="currentStep === 3" />
  </div>
</template>

<script>
import StepOneForgotPassword from '@/components/login/stepForgotPassword/StepOne.vue'
import StepTwoChangePassword from '@/components/login/stepForgotPassword/StepTwo.vue'
import StepThreeChangedPassword from '@/components/login/stepForgotPassword/StepThree.vue'

export default {
  name: 'ForgotPassword',
  components: {
    StepThreeChangedPassword,
    StepTwoChangePassword,
    StepOneForgotPassword
  },
  data() {
    return {
      currentStep: 1,
      email: null
    }
  },
  methods: {
    setStep(step) {
      this.currentStep = step
    },
    setEmail(email) {
      this.email = email
    }
  }
}
</script>

<template>
  <div class="row">
    <div class="col-md-5 col-lg-4 left">
      <div class="column">
        <div class="col">
          <div class="logo"></div>
        </div>
        <div class="col-10 left-content">
          <span>{{ step }}/{{ steps }}</span>
          <div>Projet</div>

          <p>
            Dans Bylaw, tous est organisé par Projet.
          </p>

          <p>
            Il vous suffit de créer un projet et d’ajouter les documents sources
            pour que notre intelligence artificielle puisse extraire et afficher
            les informations qu’ils contiennent en toute simplicité.
          </p>

          <div>
            <p class="p-bold" style="font-size: 17px; margin-bottom: 5px">
              Dans la page projet vous pouvez :
            </p>
            <ul>
              <li>
                - Filtrer les informations de toutes les façons possibles.
              </li>
              <li>
                - Voir et modifier toutes les informations extraites, ainsi que
                d'en ajouter manuellement.
              </li>
              <li>
                - Créer des nouveaux documents grâce à ces informations.
              </li>
              <li>
                - Envoyer des messages dans la messagerie dédiée au projet.
              </li>
            </ul>
          </div>
        </div>
        <div class="col triple-button">
          <q-btn label="Retour" class="btn-white" @click="back" />
          <q-btn label="Passer" class="btn-white" @click="skip" />
          <q-btn label="Suivant" class="btn-blue" @click="setStepGuide" />
        </div>
      </div>
    </div>
    <div class="col-md-7 col-lg-8 right">
      <div class="row">
        <div class="col-10 offset-1">
          <img src="@/assets/images/registration-steps/step-two.svg" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    step: {
      type: Number,
      default: 1
    },
    steps: {
      type: Number,
      default: 6
    }
  },
  methods: {
    skip() {
      this.$emit('skip')
    },
    setStepGuide() {
      this.$emit('setStepGuide', this.step + 1)
    },
    back() {
      this.$emit('setStepGuide', this.step - 1)
    }
  }
}
</script>

<template>
  <q-form @submit="onSubmit" class="q-gutter-md">
    <q-input
      v-if="!settings"
      class="mb-40"
      square
      outlined
      bottom-slots
      v-model="code"
      placeholder="Entrez le code"
      type="text"
      lazy-rules
      :rules="[val => (val && val.length > 0) || 'Veuillez entrer votre code']"
    >
      <template v-slot:hint>
        Code reçu par email
      </template>
    </q-input>

    <q-input
      v-else
      class="mb-40"
      square
      outlined
      bottom-slots
      v-model="oldPassword"
      placeholder="Old password"
      :type="typeOld"
      lazy-rules
      :rules="[val => checkPassword(val) || 'Please type your old password']"
    >
      <template v-slot:append>
        <q-avatar>
          <img
            src="../../assets/icons/eye.svg"
            @click="typeOld = typeOld === 'password' ? 'text' : 'password'"
          />
        </q-avatar>
      </template>
      <template v-slot:hint>
        Old password
      </template>
    </q-input>

    <q-input
      class="mb-40"
      square
      outlined
      bottom-slots
      v-model="password"
      placeholder="Entrez le mot de passe"
      :type="type"
      lazy-rules
      :rules="[
        val =>
          checkPassword(val) || 'Veuillez entrer votre nouveau mot de passe'
      ]"
    >
      <template v-slot:append>
        <q-avatar>
          <img
            src="../../assets/icons/eye.svg"
            @click="type = type === 'password' ? 'text' : 'password'"
          />
        </q-avatar>
      </template>
      <template v-slot:hint>
        Nouveau mot de passe
      </template>
    </q-input>

    <q-input
      square
      outlined
      bottom-slots
      v-model="confirmPassword"
      placeholder="Confirmer le mot de passe"
      :type="typeConfirm"
      lazy-rules
      :rules="[
        val => checkConfirmPassword(val) || 'Veuillez confirmer le mot de passe'
      ]"
    >
      <template v-slot:append>
        <q-avatar>
          <img
            src="../../assets/icons/eye.svg"
            @click="
              typeConfirm = typeConfirm === 'password' ? 'text' : 'password'
            "
          />
        </q-avatar>
      </template>
      <template v-slot:hint>
        Confirmer le mot de passe
      </template>
    </q-input>

    <div class="mt-40 actions-button">
      <q-btn
        v-if="settings"
        label="Annuler"
        class="btn-cancel"
        type="button"
        @click="$emit('closeModal')"
      />

      <q-btn
        label="Sauvegarder le mot de passe"
        class="btn-blue"
        type="submit"
      />

      <q-btn
        v-if="!settings"
        label="Se connecter"
        class="btn-white"
        type="button"
        @click="$router.push('login')"
      />
    </div>
  </q-form>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'FormChangePassword',
  props: {
    email: {
      type: String,
      default: ''
    },
    settings: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      password: null,
      confirmPassword: null,
      code: null,
      oldPassword: null,
      type: 'password',
      typeOld: 'password',
      typeConfirm: 'password'
    }
  },
  computed: {
    ...mapGetters('users', ['getCurrentUser'])
  },
  methods: {
    async onSubmit() {
      try {
        if (!this.settings) {
          await this.$Amplify.Auth.forgotPasswordSubmit(
            this.email,
            this.code,
            this.password
          )
          this.$emit('setStep', 3)
        } else {
          const user = await this.$Amplify.Auth.currentAuthenticatedUser()
          await this.$Amplify.Auth.changePassword(
            user,
            this.oldPassword,
            this.password
          )

          const { colorEmpty, iconEmpty } = this.$q.config.alert
          this.showNotify(colorEmpty, iconEmpty, 'Mot de passe modifié')
          this.$emit('closeModal')
        }
      } catch (err) {
        const error = err?.message || 'Une erreur est survenue'

        const message =
          error.toLowerCase().indexOf('password') > -1
            ? "Votre mot de passe n'est pas assez robuste"
            : 'Le code est faux'

        const { color, icon } = this.$q.config.alert
        this.showNotify(color, icon, message)
      }
    },
    showNotify(color, icon, message) {
      const { timeout, position } = this.$q.config.alert
      this.$q.notify({ color, icon, message, position, timeout })
    },
    checkConfirmPassword(val) {
      if (val !== this.password) return 'Les mots de passe ne correspondent pas'
      if (val && val.length < 6) return 'Taille minimum de 6 caractères'
      if (val && val.length >= 6) return true
    },
    checkPassword(val) {
      if (val && val.length < 6) return 'Taille minimum de 6 caractères'
      if (val && val.length >= 6) return true
    }
  }
}
</script>

<template>
  <q-form @submit="onSubmit" class="q-gutter-md">
    <q-input
      class="mb-40"
      square
      outlined
      bottom-slots
      v-model="email"
      placeholder="Entrez votre email"
      type="email"
      lazy-rules
      :rules="[val => checkEmail(val) || 'Veuillez entrer votre email']"
    >
      <template v-slot:hint>
        Adresse email
      </template>
    </q-input>

    <div>
      <q-btn
        label="Recevoir l'email de récupération"
        class="btn-blue"
        type="submit"
      />
      <q-btn
        label="Se connecter"
        class="btn-white"
        type="button"
        @click="$router.push('/login')"
      />
    </div>
  </q-form>
</template>

<script>
export default {
  name: 'FormForgotPassword',
  data() {
    return {
      email: null
    }
  },
  methods: {
    async onSubmit() {
      await this.$Amplify.Auth.forgotPassword(this.email)
        .then(res => res.json())
        .catch(e => e)
      this.$emit('setStep', 2)
      this.$emit('setEmail', this.email)
    },
    showNotify(color, icon, message) {
      const { timeout, position } = this.$q.config.alert
      this.$q.notify({ color, icon, message, position, timeout })
    },
    checkEmail(val) {
      if (val && val.length && !this.validateEmail(val))
        return 'Incorrect email'
      if (val && val.length > 0) return true
    },
    validateEmail(email) {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(String(email).toLowerCase())
    }
  }
}
</script>

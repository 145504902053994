<template>
  <div class="row">
    <div class="col-md-5 col-lg-4 left">
      <div class="column">
        <div class="col">
          <div class="logo"></div>
        </div>
        <div class="col-10 left-content">
          <span>{{ step }}/{{ steps }}</span>
          <div>Modèles</div>

          <p>
            Les modèles sont les documents pré-remplis qui sont utilisés pour
            créer les documents finaux. Les modèles facilitent grandement le
            travail des avocats.
          </p>

          <p>
            Pour créer un modèle, il vous suffit de choisir votre document de
            base (de type Word ou Excel) et d’ajouter des placeholders sous
            forme d’entités et d’informations.
          </p>

          <p>
            Pour cela, ajouter l’information que vous souhaitez, cliquer sur
            “Placer” et selectionnez son emplacement dans le document. Un
            placeholder peut-être placé autant de fois que vous le souhaitez.
          </p>

          <p>
            Lors de la création de documents, les placeholders seront
            automatiquement remplacé par la bonne information.
          </p>
        </div>
        <div class="col triple-button">
          <q-btn label="Retour" class="btn-white" @click="back" />
          <q-btn label="Passer" class="btn-white" @click="skip" />
          <q-btn label="Suivant" class="btn-blue" @click="setStepGuide" />
        </div>
      </div>
    </div>
    <div class="col-md-7 col-lg-8 right">
      <div class="row">
        <div class="col-10 offset-1">
          <img src="@/assets/images/registration-steps/step-four.svg" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    step: {
      type: Number,
      default: 1
    },
    steps: {
      type: Number,
      default: 6
    }
  },
  methods: {
    skip() {
      this.$emit('skip')
    },
    setStepGuide() {
      this.$emit('setStepGuide', this.step + 1)
    },
    back() {
      this.$emit('setStepGuide', this.step - 1)
    }
  }
}
</script>

<template>
  <div class="container">
    <div class="logo"></div>

    <div class="form-container">
      <div class="mt-30">
        Bienvenue dans Bylaw
      </div>

      <p class="mb-40">
        Votre mot de passe a été correctement enregistré. Laissez-vous guider
        dans l’application en suivant ces quelques étapes.
      </p>

      <div class="bottom-buttons">
        <q-btn class="btn-white" label="Passer" @click="skip" />
        <q-btn class="btn-blue" label="Commencer" @click="startGuide" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    skip() {
      sessionStorage.removeItem('isRegistered')
      this.$router.push('/')
    },
    startGuide() {
      this.$emit('startGuide')
    }
  }
}
</script>

<template>
  <div class="container">
    <div class="logo"></div>

    <div class="form-container">
      <div>Bienvenue, {{ name }}.</div>
      <p>Vous êtes enregistré dans Bylaw avec l’adresse mail : {{ email }}</p>

      <q-separator />

      <div class="pt-90">Enregistrement</div>
      <p class="mb-40">
        Veuillez choisir un mot de passe pour finaliser l’enregistrement
      </p>

      <FormRegistration @registered="registered" />
    </div>
  </div>
</template>

<script>
import FormRegistration from '@/components/registration/FormRegistration.vue'

export default {
  components: {
    FormRegistration
  },
  data() {
    return {
      email: '',
      name: ''
    }
  },
  created() {
    // TODO username
    const { email, name } = this.$route.params

    this.email = email
    this.name = name
  },
  methods: {
    registered() {
      this.$emit('registered')
    }
  }
}
</script>

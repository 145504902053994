<template>
  <div class="container">
    <div class="logo"></div>
    <div class="form-container">
      <div>Mot de passe oublié ?</div>
      <p>Suivez ces trois étapes pour le récupérer :</p>
      <ul>
        <li>
          1. Indiquer votre adresse mail
        </li>
        <li>
          2. Ouvrez l’email que vous avez reçu
        </li>
        <li>
          3. Suivez les instructions pour créer votre nouveau mot de passe
        </li>
      </ul>
      <FormForgotPassword @setStep="setStep" @setEmail="setEmail" />
    </div>
  </div>
</template>

<script>
import FormForgotPassword from '@/components/login/FormForgotPassword.vue'

export default {
  name: 'StepOneForgotPassword',
  components: {
    FormForgotPassword
  },
  methods: {
    setStep(step) {
      this.$emit('setStep', step)
    },
    setEmail(email) {
      this.$emit('setEmail', email)
    }
  }
}
</script>
